<template>
  <div class="root_cont blue_bg">
    <div class="container">
      <h2 class="text-center">{{ t.head_1 }}</h2>
      <div class="row mt-5">
        <div class="col-12 col-md-4">
          <div class="">
            <h5>{{ t.h_1 }}</h5>
            <p>Trg N.Š. Zrinskog 14,</p>
            <p>Zagreb, Croatia</p>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="">
            <h5>{{ t.h_2 }}</h5>
            <p>Trg Franje Tuđmana 3,</p>
            <p>21000, Split, Croatia</p>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="">
            <h5>{{ t.h_3 }}</h5>
            <p>hello@terraadriatica.hr</p>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="">
            <h5>{{ t.h_4 }}</h5>
            <p>Audley House,</p>
            <p>12 Margaret Street,</p>
            <p>London W1W 8RH</p>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="">
            <h5>{{ t.h_5 }}</h5>
            <p>33-39 Riley Street,</p>
            <p>Woolloomooloo.</p>
            <p>NSW 2011, Sydney</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  const t =
    //<i18n>
{
  "en": {
    "head_1": "OFFICE LOCATIONS",
    "h_1": "Croatian Headquarters:",
    "h_2": "Split Office",
    "h_3": "Contact e-mail",
    "h_4": "London office",
    "h_5": "Sydney office"
  },
  "hr": {
    "head_1": "GDJE SMO",
    "h_1": "Hrvatska - sjedište:",
    "h_2": "Split",
    "h_3": "Kontakt",
    "h_4": "London",
    "h_5": "Sydney"
  },
  "es": {
    "head_1": "OFICINAS",
    "h_1": "Sede en Croacia:",
    "h_2": "Oficina de Split",
    "h_3": "Contacto",
    "h_4": "Oficina de Londres",
    "h_5": "Oficina de Sydney"
  }
}
//</i18n>


  export default {
    name: "OfficeLocations",
    data() {
      return {
        t: t[this.$lang]
      }
    }
  }
</script>

<style scoped>

  .root_cont {

    padding: 40px 0;
  }

  p {
    margin-bottom: 0;
  }

  .row > div {
    margin-bottom: 2rem;
  }

  @media (max-width: 1200px) {}

  @media (max-width: 992px) {
    .midd {
      padding: 0 40px;
    }
  }

  @media (max-width: 768px) {
    .midd {
      padding: 0 20px;
    }
  }

  @media (max-width: 576px) {}

</style>
