<template>
  <div class="img_cont">
    <template v-if="image_loaded === 2">
      <div ref="image" class="image image_bg_aps"></div>
      <div ref="grid_1" class="grid-1 image_bg_aps active"></div>
    </template>
  </div>
</template>

<script>
export default {
  name: "AboutGridAnimation",
  data() {
    return {
      image_loaded: 0
    };
  },
  mounted() {
    const vm = this;

    vm.image_loaded = 0;

    const img1 = new Image();
    img1.onload = () => vm.image_loaded++;
    img1.src = "/static/images/AboutPodloga.jpg";

    const img2 = new Image();
    img2.onload = () => vm.image_loaded++;
    img2.src = "/static/images/AboutGrid.png";
  }
};
</script>

<style scoped>
.img_cont {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(4, 43, 93, 1); /* Used if the image is unavailable */
}

.image_bg_aps {
  background-position-x: center;
  background-position-y: 14%;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}

.image {
  background-image: url("/static/images/AboutPodloga.jpg"); /* The image used */
  z-index: 1;
}

.grid-1 {
  background-image: url("/static/images/AboutGrid.png"); /* The image used */
  z-index: 2;

  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
}

.grid-1.active {
  animation: grid-show-1 4000ms cubic-bezier(0, 0, 0.3, 1);
  animation-delay: 500ms;
  animation-fill-mode: forwards;
}

@keyframes grid-show-1 {
  from {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
  }
  to {
    clip-path: polygon(0 0%, 100% 0%, 100% 100%, 0% 100%);
  }
}

@media (max-width: 576px) {
  .img_cont {
    position: relative;
    height: 250px;
  }
}
</style>
