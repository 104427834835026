<template>
  <div>
    <TopContAbout></TopContAbout>
    <TAStaff></TAStaff>
    <OfficeLocations></OfficeLocations>
  </div>
</template>

<script>
import TopContAbout from "../../components/web/about/TopContAbout";
import TAStaff from "../../components/web/about/TAStaff";
import OfficeLocations from "../../components/web/about/OfficeLocations";
export default {
  name: "About",
  components: { OfficeLocations, TAStaff, TopContAbout }
};
</script>

<style scoped></style>
