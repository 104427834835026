<template>
  <div class="root_cont">
    <div class="position-relative">
      <AboutGridAnimation></AboutGridAnimation>

      <div class="t_cont">
        <div class="blue_bg">
          <div class="container">
            <div class="t_text">
              <h2>
                <span v-html="t.head_1"></span>
              </h2>
              <h5 class="mt-4 font-weight-normal" v-html="t.h_1"></h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="yellow_bg">
      <div class="container s_text">
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <p v-html="t.p_1"></p>
            <div class="text-center">
              <img
                style="width: 85%"
                src="/static/images/SLIKA-SATELIT-plava.png"
                alt=""
              />
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <p v-html="t.p_2"></p>
            <p class="ol font-weight-bold">
              <span class="num">1)</span>
              <span v-html="t.p_3"></span>
            </p>
            <p class="ol font-weight-bold">
              <span class="num">2)</span>
              <span v-html="t.p_4"></span>
            </p>
            <p>
              <span v-html="t.p_5"></span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="blue_bg padd_cont">
      <div class="container">
        <h2 class="text-center">
          <span v-html="t.h_2"></span>
        </h2>
        <div class="row mt-4 pt-2">
          <div class="col-md-8 col-sm-12 mob-order-2">
            <p class="">
              <span v-html="t.p_6"></span>
            </p>
            <a
              :href="`/${$lang}/code_of_ethics`"
              target="_blank"
            >
              <span v-html="t.download_broshure"></span>
            </a>
          </div>
          <div class="col-md-4 col-sm-12">
            <div class="text-center">
              <img
                class="border-shadow"
                width="150"
                :src="
                  $lang === 'hr'
                    ? '/static/documents/CODEofETHICS-HR.png'
                    : '/static/documents/CODEofETHICS-ENG.png'
                "
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const t =
  //<i18n>
{
  "en": {
    "head_1": "ABOUT TERRA ADRIATICA",
    "h_1": "Terra Adriatica was formed to challenge what has long held to be a fact in Croatia: that establishing clean title to some land is too complex, too expensive and too time-consuming to undertake; in essence, \"it is impossible\".",
    "p_1": "Our founders tested this assumption by asking the question: If time and cost was of no consequence, can it be done? The answer, as it happens, is an emphatic yes. No matter the complication.",
    "p_2": "We have spent 3 years developing Artificial Intelligence-based technology, automated processes and a deep understanding of how the law operates in order to create a range of products and services that provide potential landowners with",
    "p_3": "the ability to Search and Assess what rights they may have to land and",
    "p_4": "an inexorable route for those with a valid claim to unlock that value through sale within a workable timeframe and at no upfront cost.",
    "p_5": "By applying advanced technology and innovative finance solutions to solve these complex challenges, we will not only realise value for our clients but also the wider economy as the benefit of unlocking the estimated €billions of real estate attracts inward investment to support jobs and growth in Croatia.",
    "h_2": "OUR CODE OF ETHICS",
    "p_6": "Terra Adriatica's Code of Ethics represents the Company's core values according to which all business-related actions will be performed. ",
    "download_broshure": "DOWNLOAD"
  },
  "hr": {
    "head_1": "O NAMA",
    "h_1": "Terra Adriatica je pokrenuta kako bi se suočila s onime što se dugo smatralo činjenicom u Hrvatskoj, a to je da je uspostavljanje čistog zemljišnog vlasništva pretežak, preskup, prekompliciran i dugotrajan pothvat – moglo bi se reći i nemoguć.",
    "p_1": "Naši osnivači ispitali su tu pretpostavku postavivši sljedeće pitanje: ako vrijeme i novac nisu bitni, može li se to postići? Odgovor je bio nedvosmislen: \"da\".  Bez obzira na komplikacije.",
    "p_2": "Proveli smo 3 godine razvijajući UI tehnologiju, automatizirane procese i duboko razumijevanje hrvatskih zakona da bismo mogli stvoriti niz proizvoda i usluga za zemljovlasnike. Time osiguravamo:",
    "p_3": "mogućnost pretrage i procjene potencijalnih prava na zemljište;",
    "p_4": "siguran put do otključavanja vrijednosti zemljišta prodajom, za sve koji imaju legitimno pravo na zemljište, i to u realnom vremenskom okviru i bez ikakvih troškova unaprijed.",
    "p_5": "Uz primjenu napredne tehnologije i inovativnih financijskih rješenja, rješavanjem ovih složenih izazova ne stvaramo vrijednost samo našim klijentima već i hrvatskom gospodarstvu uopće – otključavamo milijarde eura vrijednosti, što će privući investicije i ujedno potaknuti gospodarski rast i nova zapošljavanja.",
    "h_2": "NAŠ ETIČKI KODEKS",
    "p_6": "Etički kodeks Terra Adriatice predstavlja temeljne etičke vrijednosti projekta, u skladu s kojima će se odvijati sve poslovne aktivnosti.",
    "download_broshure": "PREUZMI"
  },
  "es": {
    "head_1": "SOBRE TERRA ADRIATICA",
    "h_1": "\"Terra Adriatica was formed to challenge what has long held to be a fact in Croatia: that establishing clean title to some land is too complex, too expensive and too time-consuming to undertake; in essence, \"\"it is impossible\"\".\"",
    "p_1": "Nuestros fundadores probaron esta suposición haciendo la pregunta: Si el tiempo y el coste no fueran importantes, ¿se podría hacer? La respuesta, como sucede, es un sí rotundo. No importa la complicación.",
    "p_2": "Hemos pasado tres años desarrollando la tecnología basada en inteligencia artificial, procesos automatizados y una profunda comprensión de cómo funciona la ley para poder crear una gama de productos y servicios que proporcionen a los posibles propietarios",
    "p_3": "la capacidad de buscar y evaluar qué derechos de terrenos podrían tener y",
    "p_4": "una ruta inexorable para aquellos con un reclamo válido para desbloquear ese valor a través de la venta dentro de un plazo de tiempo viable y sin coste inicial.",
    "p_5": "Al aplicar tecnología avanzada y soluciones financieras innovadoras para resolver estos complejos desafíos, no solo realizaremos valor para nuestros clientes, sino también para la economía en general, ya que el beneficio de desbloquear los miles de millones de euros estimados de bienes raíces atrae la inversión extranjera para apoyar el empleo y el crecimiento en Croacia.",
    "h_2": "NUESTRO CÓDIGO DE ÉTICA",
    "p_6": "El Código de Ética de Terra Adriatica representa los valores fundamentales de la Compañía según los cuales se realizarán todas las acciones relacionadas con el negocio.",
    "download_broshure": "DESCARGAR"
  }
}
//</i18n>

import AboutGridAnimation from "./AboutGridAnimation";

export default {
  name: "TopContAbout",
  components: { AboutGridAnimation },
  data() {
    return {
      t: t[this.$lang]
    };
  }
};
</script>

<style scoped>
.root_cont {
}

.t_cont {
  padding-top: 250px;
  width: 100%;
  text-align: center;
  color: #fffff3;
  position: relative;
  z-index: 3;
}

.t_cont .blue_bg {
  background-color: rgba(4, 43, 93, 0.8);
}

.t_text {
  padding-top: 40px;
  padding-bottom: 30px;
  line-height: 10px;
  text-align: center;
}

.s_text {
  padding-top: 30px;
}

h2 span {
  display: block;
}

h5 {
  line-height: 1.5;
}

p {
  margin-bottom: 30px;
}

.ol {
  margin-left: 22px;
}

.ol span.num {
  position: absolute;
  left: 12px;
}

.padd_cont {
  padding: 40px 0;
}

a {
  color: #fffff3;
  text-decoration: underline;
}

.border-shadow {
  -webkit-box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.5);
}

@media (max-width: 1200px) {
}

@media (max-width: 992px) {
}

@media (max-width: 768px) {
  h1 span {
    display: inline;
  }
}

@media (max-width: 576px) {
  .t_cont {
    padding-top: 0;
  }

  .t_cont .blue_bg {
    background-color: rgba(4, 43, 93, 1);
  }

  .mob-order-1 {
    order: 1;
  }

  .mob-order-2 {
    order: 2;
    margin-top: 15px;
  }
}
</style>
