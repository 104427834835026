<template>
  <div class="root_cont TAStaffComponent">
    <div class="our_staff_ta yellow_bg pt-5">
      <div class="container">
        <h2 class="text-center">{{ t.head_1 }}</h2>
        <div class="row mt-5">
          <Card img="Sasa.jpg" name="Saša Božović" title="senior project manager" main_cont_add_classes="offset-md-1_5" />
          <Card img="Maja.jpg" name="Maja Odak" title="head of diaspora development" />
          <Card img="Kresimir.jpg" name="Krešimir Babel" title="data scientist" />
          <Card img="Marko.jpg" name="Marko Horvat" title="data engineer" main_cont_add_classes="offset-md-1_5" />
          <Card img="Donat.jpg" name="Donat Sorić" title="full stack developer" />
          <Card img="LukaG.jpg" name="Luka Grubor" title="strategic account executive" />
          <Card img="Radovan.jpg" name="Radovan Švob" title="ux designer" main_cont_add_classes="offset-md-1_5" />
          <Card img="Tamara.jpg" name="Tamara Tomek" title="community manager" />
          <Card img="Jasmina.jpg" name="Jasmina Vlah" title="office manager" />
        </div>
      </div>
    </div>

    <div class="management_ta blue_bg pt-5">
      <div class="container">
        <h2 class="text-center">{{ t.head_2 }}</h2>
        <div class="row mt-5">
          <Card img="Arthur.png" name="Arthur Hughes" :title="`group founder <span style='font-size: 12px'>&</span> ceo`" />
          <Card img="Davor.png" name="Davor Lauc" title="group chief science officer" />
          <Card img="Anthony.png" name="Anthony Johnson" title="group md" />
          <Card img="Geoff.png" name="Geoff Mayhill" title="group corporate governance director" />
          <Card img="Julian1.png" name="Julian Kelly" title="group fd" main_cont_add_classes="offset-md-1_5" />
          <Card img="Ivan.png" name="Ivan Gotovac" title="senior advisor" />
          <Card img="Andro.png" name="Andro Čečuk" title="senior advisor" />
        </div>
      </div>
    </div>

    <div class="legal_team_ta yellow_bg pt-5">
      <div class="container">
        <h2 class="text-center">{{ t.head_3 }}</h2>
        <div class="row mt-5">
          <Card img="Jelena.jpg" name="Jelena Matulić" title="legal partner" main_cont_add_classes="offset-md-1_5" />
          <Card img="Vana.jpg" name="Vana Vrsalović" title="legal partner" />
          <Card img="Boris.jpg" name="Boris Bilić" title="legal partner" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const t =
  //<i18n>
{
  "en": {
    "head_1": "OUR TEAM",
    "head_2": "LEADERSHIP TEAM",
    "head_3": "LEGAL TEAM"
  },
  "hr": {
    "head_1": "NAŠ TIM",
    "head_2": "UPRAVA",
    "head_3": "PRAVNI TIM"
  },
  "es": {
    "head_1": "NUESTRO EQUIPO",
    "head_2": "EQUIPO DIRECTIVO",
    "head_3": "EQUIPO LEGAL"
  }
}
//</i18n>

export default {
  name: "TAStaff",
  components: {Card},
  data() {
    return {
      t: t[this.$lang]
    };
  }
};

function Card({img, name, title, main_cont_add_classes}) {
  return (
    <div className={'col-md-3 col-6' + (main_cont_add_classes ? ' '+main_cont_add_classes : '')}>
      <div className="text-center">
        <img src={'/static/images/staff/' + img} alt=""/>
        <h6 className="mt-2">{name}</h6>
        <h6 className="small-caps" vHtml={title} />
      </div>
    </div>
  )
}
</script>

<style lang="scss">
.TAStaffComponent {
  .our_staff_ta {
  }

  .management_ta {
  }

  .legal_team_ta {
  }

  img {
    width: 70%;
    border-radius: 50%;
    filter: grayscale(80%);
    transition: 0.3s;
  }

  img:hover {
    filter: grayscale(0%);
  }

  .row > div {
    margin-bottom: 30px;
  }

  .offset-md-1_5 {
    margin-left: 12.5%;
  }

  h6 {
    font-weight: 400 !important;
    margin-top: -5px;
  }

  .small-caps {
    font-variant: small-caps;
  }

  @media (max-width: 1200px) {
  }

  @media (max-width: 992px) {
  }

  @media (max-width: 768px) {
    .offset-md-1_5 {
      margin-left: 0;
    }
  }

  @media (max-width: 576px) {
  }
}
</style>
